import React, { Component } from "react";
import { setTitle } from "../../common/js/tools";
import Header from "../../components/header";
// import Modal from "../../components/modal";
import HoverArea from "../../components/hoverArea";
// import InputArea from "../../components/inputArea";
import "./index.less";
import logo from "../../common/images/official/logo.png";
import top1 from "../../common/images/official/top1.png";
import top2 from "../../common/images/official/top2.png";
import top3 from "../../common/images/official/top3.png";
import top5 from "../../common/images/official/top5.png";

import top4_top from "../../common/images/official/top4/top4_top.png";

class Official extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navList: [{
                id: "home",
                name: "HOME"
            }, {
                id: "services",
                name: "PROCESS"
            }, {
                id: "products",
                name: "ABOUT"
            }, {
                id: "process",
                name: "PRODUCTS"
            }, {
                id: "about",
                name: "CHARACTERS"
            }], // 顶部导航栏
            navIndex: 0, // 选中过的nav
            officialName: "Bitnn Lending Corp", // 网站名
            sectionList: [{
                navIndex: 0,
                id: "home",
                imgSrc: top1,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 1,
                id: "services",
                imgSrc: top2,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 2,
                id: "products",
                imgSrc: top3,
                isInteractive: false, // 是否交互
            }, {
                navIndex: 3,
                id: "process",
                imgSrc: "",
                isInteractive: true, // 是否交互
            }, {
                navIndex: 4,
                id: "about",
                imgSrc: top5,
                isInteractive: false, // 是否交互
            }],
            isModal: false,
            iwidth: 0,
            clickedNavIndex: 0, // 点击的id
            scrolledNavIndex: 0, // 滑动的id
        }
    }
    componentDidMount() {
        setTitle(this.state.officialName);
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        })
        window.addEventListener("resize", this.resize.bind(this));
        window.addEventListener("scroll",  this.scrollToNav.bind(this), true);
    }
    // 点击滑动
    scrollToAnchor(item, index) {
        // console.log(item, index);
        this.setState({
            clickedNavIndex: index,
            navIndex: index == 3 ? 3 : this.state.scrolledNavIndex,
        });
        let elementId = item.id;
        let anchorElement = document.getElementById(elementId);
        if (anchorElement) {
            anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
        }
    }
    // 监听宽度变化
    resize() {
        let width = document.querySelector('body').offsetWidth;
        this.setState({
            iwidth: width,
        });
    }
    // 跳出弹框
    showModal() {
        this.setState({
            isModal: true,
        })
    }
    // 关闭弹框
    closeModal() {
        this.setState({
            isModal: false,
        })
    }
    // 滑动到contact
    goContact() {
        this.scrollToAnchor({
            id: "about",
            name: "ABOUT"
        }, 4);
    }
    // 滑动到services
    goService() {
        this.scrollToAnchor({
            id: "services",
            name: "SERVICES"
        }, 2);
    }
    // 滑动页面时更换id
    scrollToNav(e) {
        let anchorElementHeights = [];
        this.state.navList.map(item => {
            let height = document.getElementById(item.id).offsetTop;
            anchorElementHeights.push(height);
        })
        // console.log(anchorElementHeights);
        anchorElementHeights.map((item, index) => {
            if(e.target.scrollTop >= item && e.target.scrollTop <= anchorElementHeights[index + 1] && e.target.scrollTop <= anchorElementHeights[anchorElementHeights.length - 1]) {
                this.setState({
                    scrolledNavIndex: index,
                    navIndex: this.state.clickedNavIndex == anchorElementHeights.length - 1 ? anchorElementHeights.length - 1 : index,
                });
            } else if(e.target.scrollTop >= anchorElementHeights[anchorElementHeights.length - 1]) {
                this.setState({
                    scrolledNavIndex: anchorElementHeights.length - 1,
                    navIndex: this.state.clickedNavIndex == anchorElementHeights.length - 1 ? anchorElementHeights.length - 1 : index,
                });
            }
        })
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scrollToNav);
    }
    render() {
        return (
            <div className="P-visiomed-wrap">
                <Header
                logoLeft
                logo={logo}
                // name={this.state.officialName}
                list={this.state.navList}
                navIndex={this.state.navIndex}
                isWidth={this.state.iwidth}
                scrollToAnchor={this.scrollToAnchor.bind(this)}>
                    {/* 中间区域 child */}
                </Header>
                <section className="P-visiomed-wrap_content">
                    {this.state.sectionList.map((item, index) => (
                        <div key={"vIndex" + index} className="content_area">
                            {!item.isInteractive ? (
                                <img src={item.imgSrc} id={item.id} className="content_area_img" />
                            ) : (
                                index == 3 ? (
                                    <div className="hoverArea" id={item.id}>
                                        <img src={top4_top} className="hover_top" />
                                        <div className="hover">
                                            <HoverArea />
                                        </div>
                                    </div>
                                ) : null
                            )}
                        </div>
                    ))}
                </section>
            </div>
        )
    }
}

export default Official;
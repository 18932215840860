import React, { useState } from "react";
import "./index.less";
import top4_img1 from "../../common/images/official/top4/top4_img1.png";
import top4_img2 from "../../common/images/official/top4/top4_img2.png";
import top4_img3 from "../../common/images/official/top4/top4_img3.png";
import top4_img1_hovered from "../../common/images/official/top4/top4_img1_hovered.png";
import top4_img2_hovered from "../../common/images/official/top4/top4_img2_hovered.png";
import top4_img3_hovered from "../../common/images/official/top4/top4_img3_hovered.png";

const HoverArea = (props) => {
    const { isWidth } = props;
    const hoverList = [{
        id: 1,
        icon: top4_img1,
        hovered_icon: top4_img1_hovered,
    }, {
        id: 2,
        icon: top4_img2,
        hovered_icon: top4_img2_hovered,
    }, {
        id: 3,
        icon: top4_img3,
        hovered_icon: top4_img3_hovered,
    }];
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const onMouseEnter = (index) => {
        setHoveredIndex(index);
    }
    const onMouseLeave = () => {
        // console.log(index);
        setHoveredIndex(null);
    }
    return (
        <div className={isWidth <= 1045 ? "M-media-hoverArea" : "M-hoverArea"}>
            <ul className="M-hoverArea_list">
                {hoverList.map((item, index) => {
                    return (
                        <li 
                        key={"hlist" + index}
                        onMouseEnter={onMouseEnter.bind(this, index)}
                        onMouseLeave={onMouseLeave.bind(this)}
                        className={hoveredIndex == index ? "hoveredArea" : hoveredIndex !== null ? "not_hoveredArea" : ""}>
                            <img src={hoveredIndex == index ? item.hovered_icon : item.icon} />
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default HoverArea;